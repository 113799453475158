export const TABLE_TH = [
    {
        name: 'Id',
        sort: '',
        key: 'id',
        description: 'Id'
    },
    {
        name: 'Name',
        sort: '',
        key: 'name',
        description: 'Name'
    },
    {
        name: 'Market url',
        sort: '',
        key: 'marketUrl',
        description: 'Market url'
    },
    {
        name: 'Active',
        sort: '',
        key: 'active',
        description: 'Market url'
    },
    {
        name: 'Edit',
        sort: '',
        noSort: true,
        key: 'edit',
        description: 'Edit network'
    },
];
