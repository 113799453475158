<template>
  <div class="">
    <div
      class="coins-modal"
      tabindex="0"
    >
      <div class="coins-modal-block">
        <div class="coins-modal-block-form">
          <div class="coins-modal-block-form-title">
            Add coin
          </div>

          <div
            class="close"
            @click="closePopup"
          >
            <img
              src="../../../assets/img/close.svg"
              alt=""
            >
          </div>

          <div class="coins-modal-block-form-info-image">
            <label for="image">
              <avatar />
            </label>
            <input
              id="image"
              ref="uploadFile"
              type="file"
              @change="uploadFile"
            >
            <div
              class="coin-change"
              @click="$refs.uploadFile.click()"
            >
              Add image
            </div>
          </div>

          <div class="coins-modal-block-form-info-coin-edit">
            <div class="inputs">
              <div class="inputs-item">
                <div class="inputs-item-title">
                  Name
                </div>
                <input
                  v-model="coinData.name"
                  type="text"
                >
              </div>
              <div class="inputs-item">
                <div class="inputs-item-title">
                  Symbol
                </div>
                <input
                  v-model="coinData.symbol"
                  type="text"
                >
              </div>
<!--              <div class="inputs-item">
                <div class="inputs-item-title">
                  User fee
                </div>
                <input
                  v-model="coinData.userFee"
                  type="number"
                >
              </div>-->
              <div class="inputs-item">
                <div class="inputs-item-title">
                  Rating
                </div>
                <input
                  v-model="coinData.rating"
                  type="number"
                >
              </div>
              <div class="inputs-item">
                <div class="inputs-item-title">
                  Withdrawal round
                </div>
                <input
                  v-model="coinData.withdrawalRound"
                  type="number"
                >
              </div>
              <div class="inputs-item">
                <div class="inputs-item-title">
                  Trade round
                </div>
                <input
                  v-model="coinData.tradeRound"
                  type="number"
                >
              </div>
            </div>
            <div class="toggles">
              <div class="toggles-item">
                <div class="text">
                  Active
                </div>
                <Toggle v-model="coinData.isActive" />
              </div>
            </div>
          </div>

          <div class="coins-modal-block-form-info-coin-submit">
            <button
              class="cancel"
              @click="closePopup"
            >
              Cancel
            </button>
            <button
              class="save"
              @click="addCoinInfo"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MAIN_DOMAIN} from "@/store/config";
import Toggle from '@vueform/toggle'
import CoinsService from '../../../services/coins.service'
import FileService from '../../../services/file.service'
import Avatar from "../../../components/admin/Avatar.vue";
import {STATUSES} from "../coinData";

export default {
  name: "CoinsAddModal",
  components: {Avatar, Toggle},
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      domain: MAIN_DOMAIN,
      isChains: false,
      tableTh: STATUSES,
      file: '',
      coinData: {
        name: '',
        symbol: '',
        image: '',
        isActive: false,
        tradeRound: '',
        withdrawalRound: '',
        rating: '',
      },
    }
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.closePopup()
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.closePopup()
      }
    });
  },
  methods: {
    closePopup() {
      this.$store.commit('setAddCoinData', null);
    },
    uploadFile(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }
      this.addImage(files[0]);
    },
    addImage(file) {
      const reader = new FileReader();

      reader.onload = () => {
        this.file = file;
        this.uploadImage();
      };
      reader.readAsBinaryString(file);

    },
    uploadImage() {
      FileService.uploadFile(this.file).then((result) => {
        this.coinData.image = result.data.result.path;
      }).catch((error) => {
        console.error(error);
      })
    },
    successPopup() {
      this.$store.commit('setSuccess', {
        name: 'Add coin success',
        value: this.coinData,
      });
    },
    errorPopup() {
      this.$store.commit('setError', {
        name: 'Add coin error',
        value: this.coinData,
      });
    },
    addCoinInfo() {
      CoinsService.addCoin(this.coinData).then(() => {
        this.closePopup();
        this.successPopup();
      }).catch(() => {
        this.errorPopup();
      })
    }
  },
}
</script>


<style lang="scss">
@import "~@vueform/toggle/themes/default.css";
@import "../../../assets/styles/pages/coins/add-modal";
</style>
