<template>
  <div
    v-if="data"
    class="editUserPopup"
  >
    <div
      class="popup"
      tabindex="0"
      @click="modalOverlayClose"
      @keydown.esc="closePopup"
    >
      <div
        ref="modalOverlay"
        class="popup-block"
      >
        <div class="popup-block-form change-password">
          <div class="popup-block-form-title">
            Edit user id {{ user.id }}
            <div class="popup-block-form-title-delimeter" />
            <div class="popup-block-form-title-login">
              {{ data.login }}
            </div>
          </div>

          <div
            class="close"
            @click="closePopup"
          >
            <img
              src="../../../assets/img/close.svg"
              alt=""
            >
          </div>

          <div class="popup-block-form-input">
            <div class="row-item">
              <div class="item">
                <div class="popup-block-form-input-title">
                  Min Payment BTC
                </div>
                <input
                  v-model="user.data.minPaymentBTC"
                >
              </div>
              <div class="item">
                <div class="popup-block-form-input-title">
                  Ref share percent
                </div>
                <input
                  v-model="user.data.partnerRefShare"
                >
              </div>
            </div>
            <div class="row-item">
              <div class="item">
                <div class="popup-block-form-input-title">
                  Max deposit fix
                </div>
                <input
                  v-model="user.data.maxDepositFix"
                >
              </div>
              <div class="item">
                <div class="popup-block-form-input-title">
                  Max deposit float
                </div>
                <input
                  v-model="user.data.maxDepositFloat"
                >
              </div>
            </div>
            <div class="row-item">
              <div class="item">
                <div class="popup-block-form-input-title">
                  Partner float
                </div>
                <input
                  v-model="user.data.partnerFloat"
                >
              </div>
              <div class="item">
                <div class="popup-block-form-input-title">
                  Partner fix
                </div>
                <input
                  v-model="user.data.partnerFix"
                >
              </div>
            </div>
            <div class="toggles">
              <div class="toggles-item">
                <div class="text">
                  Is Favorite
                </div>
                <Toggle v-model="user.data.isFavorite" />
              </div>
            </div>
            <div class="popup-block-form-buttons">
              <button
                class="cancel"
                @click="closePopup"
              >
                Cancel
              </button>
              <button
                class="save"
                @click="saveUser"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button @click="closePopup">
      close
    </button>
  </div>
</template>

<script>
import UserService from "@/services/user.service";
import Toggle from '@vueform/toggle'

export default {
  name: "EditUserPopUp",
  components: {
    Toggle,
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      user: {
        id: null,
        data: {
          maxDepositFix: 0,
          maxDepositFloat: 0,
          partnerFloat: 0,
          partnerFix: 0,
          isFavorite: false
        }
      }
    }
  },
  mounted() {
    console.log(this.data.partnerProfile)
    this.user.id = this.data.id
    this.user.data = this.data;
    this.user.data.isFavorite = this.data.isFavorite;
  },
  methods: {
    saveUser() {
      let query = {
        id: this.user.data.id,
        maxDeposit: this.user.data.maxDeposit,
        minPaymentBTC: +this.user.data.minPaymentBTC,
        maxDepositFix: +this.user.data.maxDepositFix,
        maxDepositFloat: +this.user.data.maxDepositFloat,
        partnerFloat: +this.user.data.partnerFloat,
        partnerFix: +this.user.data.partnerFix,
        isFavorite: this.user.data.isFavorite,
        partnerRefShare: +this.user.data.partnerRefShare
      }

      console.log(this.user.data,'sdsdsdad')
      UserService.editUserById(this.user.id, query).then((res) => {
        this.closePopup();
        this.successPopup();
      }).catch(() => {
        this.errorPopup();
      })
    },
    successPopup() {
      this.$store.commit('setSuccess', {
        name: 'Edit user success',
      });
    },
    errorPopup() {
      this.$store.commit('setError', {
        name: 'Edit user error',
      });
    },
    closePopup() {
      this.$emit("change-fav");
      this.emitter.emit('edit-user-popup', null);
    },
    modalOverlayClose(event) {
      if (event.target === this.$refs.modalOverlay) {
        this.closePopup()
      }
    },
  }
}
</script>

<style lang="scss">
.editUserPopup {
  .row-item {
    display: flex;
    gap: 12px;
  }

  .popup-block-form {
    @media (max-width: 850px) {
      height: auto;
      border-radius: 16px;
    }
  }

  .item {
    width: 50%;
  }

  .popup-block-form-input {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .popup-block-form-buttons {
    margin-top: 0;

    @media (max-width: 1079px) {
      position: relative;
      bottom: 0;
    }
  }

  .toggles {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    &-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 15px 0;

      @media (max-width: 1079px) {
        max-width: 100%;
      }

      .text {
        font-size: 14px;
        line-height: 18px;
        color: #1B1A1C;
        font-weight: 600;
      }

      .toggle {
        width: var(--toggle-width, 34px);
        height: var(--toggle-height, 12px);
        outline: none;

        &-container {
          &:focus {
            box-shadow: none;
          }
        }
      }

      .toggle-on {
        background: var(--toggle-bg-on, rgba(10, 104, 247, 0.32));
        border-color: transparent;
      }

      .toggle-handle-on {
        transform: translateX(-91%);
        background: #0A68F7;
        box-shadow: 0 1px 2px rgba(27, 26, 28, 0.16);;
      }

      .toggle-handle-off {
        left: -2px;
      }

      .toggle-handle {
        width: 20px;
        height: 20px;
        top: -3.5px;
        box-shadow: 0 1px 1px rgba(27, 26, 28, 0.14), 0 1px 3px rgba(27, 26, 28, 0.2);
      }
    }
  }
}
</style>
