<template>
  <div
    class="status-select"
    :tabindex="tabindex"
    @blur="open = false"
  >
    <div
      v-if="disableClick"
      class="selected"
      :class="{ open: open }"
    >
      <div class="status">
        <div
          class="status-item"
          :class="className"
        >
          {{ valueModel }}
        </div>
      </div>
    </div>
    <div
      v-if="!disableClick"
      ref="reference"
      class="selected"
      :class="{ 'open': open }"
      @click="timerHandler()"
    >
      <div class="status">
        <div
          class="status-item"
          :class="className"
        >
          {{ valueModel }}
        </div>
      </div>
    </div>
    <div
      v-if="options.length && open"
      ref="floating"
      class="items"
      :style="floatingStyles"
    >
      <div class="item">
        <div
          v-if="isMobile()"
          class="title"
        >
          Status
          <span> ID {{ data.id }}</span>
        </div>
        <div
          v-for="(option, index) of options"
          :key="index"
          class="item-dropdown"
          :class="{
            'waiting': option.name === 'Waiting',
            'confirmation': option.name === 'Confirmation',
            'payment': option.name === 'Payment',
            'exchanging': option.name === 'Exchanging',
            'ExchangingInProgress': option.name === 'Exchanging in progress',
            'readyToSend': option.id === 'Ready to sending',
            'sending': option.name === 'Sending',
            'success': option.name === 'Success',
            'overdue': option.name === 'Overdue',
            'refunded': option.name === 'Refunded',
            'frozen': option.name === 'Frozen',
            'suspended': option.name === 'Suspended',
            'active': option.name === valueModel && isMobile(),
          }"
          @click="selectHandler(option)"
        >
          <div class="item-dropdown_status">
            <div class="circle" />
            <div
              v-if="option.name"
              class="default"
              :class="{
                'mob-name': isMobile()
              }"
            >
              {{ option.name }}
              <mark-icon
                v-if="option.name === valueModel && isMobile()"
                class="mark-icon"
              />
            </div>
            <div
              v-if="option.name === null"
              class="non-default"
            >
              None
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!isDeposited"
        class="desc"
      >
        statuses will be available after choosing a depot
      </div>
    </div>
  </div>
</template>

<script>
import {ref, watch, onMounted, defineComponent, getCurrentInstance, onBeforeUnmount, onUnmounted} from 'vue';
import {flip, offset, useFloating} from '@floating-ui/vue';
import MarkIcon from '../../../icons/markIcon';

export default defineComponent({
  name: 'SelectStatusPopupExchange',
  components: {MarkIcon},
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    valueInput: {
      type: Number,
      required: false,
      default: 0,
    },
    disableClick: {
      type: Boolean,
      default: false,
    },
    page: {
      type: String,
      default: '',
    },
    isDeposited: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change-select'],
  setup(props, {emit}) {
    const reference = ref(null);
    const floating = ref(null);
    const {proxy} = getCurrentInstance();

    const {floatingStyles} = useFloating(reference, floating, {
      middleware: [
        flip(),
        offset({
          crossAxis: -15,
        })],
    });

    const open = ref(false);
    const valueModel = ref('');
    const className = ref('');

    const isMobile = () => window.innerWidth < 760;

    const timerHandler = () => {
      open.value = !open.value;
    };

    const getStatus = (status) => {
      return getExchangeStatus(status);
    };

    const getExchangeStatus = (status) => {
      let name = 'Exchange';

      switch (status) {
        case 10:
          name = 'Waiting';
          break;
        case 20:
          name = 'Confirmation';
          break;
        case 30:
          name = 'Payment';
          break;
        case 40:
          name = 'Exchanging';
          break;
        case 50:
          name = 'Exchanging in progress'
          break;
        case 60:
          name = 'Ready to send'
          break;
        case 70:
          name = 'Sending';
          break;
        case 80:
          name = 'Success';
          break;
        case 110:
          name = 'Overdue';
          break;
        case 100:
          name = 'Refunded';
          break;
        case 90:
          name = 'Frozen';
          break;
        case 120:
          name = 'Suspended';
          break;
      }

      return {
        name: name,
        className: name.toLowerCase(),
      };
    };

    const selectHandler = (option) => {
      valueModel.value = option.name;
      className.value = getStatus(option.status).className;
      open.value = false;
      emit('change-select', {
        option: option,
        data: props.data,
      });
    };

    watch(() => props.valueInput, (value) => {
      const status = getStatus(value);
      valueModel.value = status.name;
      className.value = status.className;
    });

    watch(open, (value) => {
      proxy.$emit('exchange-' + props.page + '-timer', !value);
    });

    onMounted(() => {
      open.value = props.isOpen;
      const status = getStatus(props.valueInput);
      valueModel.value = status.name;
      className.value = status.className;
    });

    return {
      reference,
      floating,
      floatingStyles,
      open,
      valueModel,
      className,
      isMobile,
      timerHandler,
      getStatus,
      selectHandler,
    };
  },
});
</script>


<style lang="scss">

.status-select {

  .selected {
    border-radius: 6px;
    color: #1B1A1C;
    cursor: pointer;
    user-select: none;
    padding: 0;

    &::after {
      display: none;
    }

    .open {
      background: rgba(159, 162, 180, 0.06);
      border-radius: 8px;
    }

    .status {
      display: flex;

      &-item {
        padding: 8px;
        border-radius: 6px;
        font-size: 12px;
        font-weight: 700;
        color: white;
        line-height: 1;
      }

      .waiting {
        background: #A1BBD9;
      }

      .success {
        background: #02C076;
      }

      .confirmation {
        background: #44A5FF;
      }

      .payment {
        background: #0065FF;
      }

      .exchanging {
        background: #0065FF;
      }

      .sending, .ready.to.send {
        background: #B441EA;
      }

      .overdue {
        background: #FF783E;
      }

      .refunded, .frozen, .suspended {
        background: #FF603D;
      }
    }
  }

  .items {
    color: #1B1A1C;
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(15, 0, 58, 0.12);
    border-radius: 12px;
    z-index: 999;
    width: 200px;
    line-height: 50px;
    padding: 8px 0;
    box-sizing: border-box;

    .item {
      cursor: pointer;
      user-select: none;
      background: none;
      transition: all .3s ease-in-out;

      .success {
        .circle {
          background: #02C076;
        }
      }

      .waiting {
        .circle {
          background: #A1BBD9;
        }
      }

      .sending, .ready.to.send {
        .circle {
          background: #B441EA;
        }
      }

      .canceled {
        .circle {
          background: #FF783E;
        }
      }

      .waiting {
        .circle {
          background: #A1BBD9;
        }
      }

      .success {
        .circle {
          background: #02C076;
        }
      }

      .confirmation {
        .circle {
          background: #44A5FF;
        }
      }

      .payment {
        .circle {
          background: #44A5FF;
        }
      }

      .disabled {
        color: #7C86A0;

        &:hover {
          cursor: default;
          background: none;
        }

        .default {
          &:after {
            content: '*';
            max-height: 100%;
          }
        }
      }

      .exchanging {
        .circle {
          background: #0065FF;
        }
      }

      .overdue {
        .circle {
          background: #FF783E;
        }

      }

      .refunded, .frozen, .suspended {
        .circle {
          background: #FF603D;
        }
      }

      &-dropdown {
        width: 100%;
        padding-left: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        transition: all .3s ease-in-out;

        &:hover {
          background: rgba(27, 26, 28, 0.03);
        }

        .default {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          padding: 8px 0;
        }


        &_status {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
        }

        .circle {
          height: 10px;
          width: 10px;
          /*background: #0A68F7;*/
          border-radius: 90px;
        }
      }
    }

    .desc {
      padding: 4px 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7C86A0;
    }
  }

  .selectHide {
    display: none;
  }

  .disabled {
    opacity: 1;
  }
}


.select-popup-item {
  .items {
    left: unset;
    right: 0;
  }
}

</style>
