/*export const getStatusData = (status) => {
  switch (status) {
    case 0:
      return {name: 'Remaining'};
    case 1:
      return {name: 'Pay'};
    case 2:
      return {name: 'Exchange'};
    case 5:
      return {name: 'Withdrawal'};
    case 6:
      return {name: 'Completed'};
    case 10:
      return {name: 'Overdue'};
    case 11:
      return {name: 'Returned'};
    case 12:
      return {name: 'Hold'};
    case 13:
      return {name: 'Profit'};
  }
}*/

export const validEmail = (email) => {
  let symbols = /^[a-zA-Z0-9](?!.*\.\.)[a-zA-Z0-9._%+-]*[a-zA-Z0-9]@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/;

  if (!email) {
    return true;
  }

  return email.length <= 300 && symbols.test(email);
}

export const isPasswordValidLength = (password) => {
  if (!password) {
    return true;
  }
  return password.length >= 7 && password.length <= 300;
}

export const hasPasswordCapitalize = (password) => {
  if (!password) {
    return true;
  }
  const uppercaseRegex = /[A-Z]/;
  return uppercaseRegex.test(password);
}

export const hasPasswordSpecialCharacter = (password) => {
  if (!password) {
    return true;
  }
  const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
  return specialCharRegex.test(password);
};


