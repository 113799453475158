<template>
  <div class="">
    <div
      class="coins-modal"
      tabindex="0"
      @keydown.esc="$emit('close')"
      @click="modalOverlayClose"
    >
      <div
        ref="modalOverlay"
        class="coins-modal-block"
      >
        <div class="coins-modal-block-form">
          <div class="coins-modal-block-form-title">
            Edit network
          </div>

          <div
            class="close"
            @click="$emit('close')"
          >
            <img
              src="../../../assets/img/close.svg"
              alt=""
            >
          </div>

          <div class="coins-modal-block-form-info-coin-edit">
            <div class="inputs">
              <div class="inputs-item large">
                <div class="inputs-item-title">
                  Network name
                </div>
                <input
                  :readonly="true"
                  type="url"
                  :placeholder="data.shortName"
                >
              </div>
              <div class="inputs-item large">
                <div class="inputs-item-title">
                  Market URL
                </div>
                <input
                  v-model="networkData.marketUrl"
                  type="url"
                  :placeholder="data.marketUrl"
                >
              </div>
              <div class="toggles">
                <div class="toggles-item">
                  <div class="text">
                    Active
                  </div>
                  <Toggle v-model="networkData.status" />
                </div>
              </div>
            </div>
          </div>

          <div class="coins-modal-block-form-info-coin-submit">
            <div
              class="coins-modal-block-form-info-coin-submit-item cancel"
              @click="$emit('close')"
            >
              Cancel
            </div>
            <div
              class="coins-modal-block-form-info-coin-submit-item save"
              @click="changeNetworkHandler"
            >
              Save
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoinsService from '../../../services/coins.service'
import {mapGetters} from 'vuex';
import Toggle from '@vueform/toggle'

export default {
  name: "NetworkEditModal",
  components: {Toggle},
  props: {
    data: {
      type: Object,
      required: true
    },
    updateNetworkList: {
      type: Function,
      required: true
    }
  },
emits: ['close'],
  data() {
    return {
      networkData: {
        marketUrl: '',
        status: false,
        id: null,
        name: ''
      },
    }
  },
  computed: {
    ...mapGetters({
      CoinNetworkData: 'getCoinNetwork',
    }),
  },
  mounted() {
    this.networkData.marketUrl = this.data.marketUrl
    this.networkData.status = this.data.status
    this.networkData.id = this.data.id
    this.networkData.name = this.data.shortName
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.closePopup()
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.closePopup()
      }
    })
  },
  methods: {
    closePopup() {
      this.$emit('close')
    },
    changeNetworkHandler() {
      CoinsService.EditNetwork(this.networkData.id,this.networkData).then(() => {
        this.successPopup();
        this.closePopup();
      }).catch(() => {
        this.errorPopup();
      });
    },
    successPopup() {
      this.$store.commit('setSuccess', {
        name: 'Network edit success',
        value: this.coinData,
      });

      this.updateNetworkList()
    },
    errorPopup() {
      this.$store.commit('setError', {
        name: 'Network edit error',
        value: this.coinData,
      });
    },
    modalOverlayClose(event) {
      if (event.target === this.$refs.modalOverlay) {
        this.closePopup()
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@vueform/toggle/themes/default.css";
@import "../../../assets/styles/pages/coins/edit-coin";

.coins-modal-block-form-title {
  margin-bottom: 24px;
}
</style>
