<template>
  <div style="height: 100%; overflow: auto">
    <div
      v-if="exchange.status === 0 && !depositData"
      class="exchange-info-form-inputs-block"
      style="height: 100%"
    >
      <div
        style="margin-bottom: 20px"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          Deposit
        </div>
        <SelectList
          v-if="filteredList"
          :list="filteredList"
          :value-input="selectedDeposit"
          :exchange="exchange"
          @update:value-input="selectedDeposit"
        />
        <div />
        <div
          class="save-deposit"
          :class="{'save-deposit-active': depositSelect}"
          @click="saveDeposit"
        >
          Save deposited
        </div>
      </div>
    </div>
    <div
      v-if="depositData"
      class="exchange-info-form-inputs-block"
    >
      <div class="exchange-info-form-inputs-block_items">
        <div
          v-if="depositData.amount"
          class="exchange-info-form-inputs-block-item"
        >
          <div class="desc">
            Amount
          </div>
          <div
            class="input"
          >
            {{ depositData.amount }}
          </div>
        </div>
        <div
          v-if="depositData.coin"
          class="exchange-info-form-inputs-block-item"
        >
          <div class="desc">
            Coin
          </div>
          <div
            class="input"
          >
            {{ depositData.coin.name }}
          </div>
        </div>
        <div
          v-if="depositData.txid"
          class="exchange-info-form-inputs-block-item"
        >
          <div class="desc">
            Transaction id
          </div>
          <div
            v-clipboard:copy="depositData.txid"
            v-clipboard:success="onCopyAddress"
            class="input input-disabled"
          >
            {{ hashFormat(depositData.txid) }}
            <copy-icon-gray class="copyIcon" />
          </div>
        </div>
      </div>
    </div>
    <CopyNotification
      v-if="isSuccessCopy"
      :text="copyText"
    />
  </div>
</template>
<script>
import OrderService from "@/services/order.service";
import SelectList from "@/components/admin/SelectList.vue";
import CopyNotification from "@/pages/notification/CopyNotification.vue";
import CopyIconGray from "@/assets/img/icons/copyIconGray.vue";

export default {
  name: "ExchangeInfoDeposit",
  components: {CopyIconGray, CopyNotification, SelectList},
  props: {
    data: {
      type: Object,
      default: null,
    },
    exchange: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      depositData: null,
      filteredList: null,
      selectedDeposit: 'Select Deposit',
      filteredNetwork: '',
      depositSelect: null,
      copyText: '',
      isSuccessCopy: true,
    }
  },
  mounted() {
    this.depositData = this.data;
    this.emitter.on('exchange-deposit', (option) => {
      this.depositSelect = option
    })
    if (this.exchange.status === 0) {
      OrderService.getListDeposit().then((res) => {
        this.filteredNetwork = this.exchange.from.shortName;
        this.filteredList = res.data.result.filter((item) => {
          return item.coin === this.filteredNetwork
        })
      })
    }
  },
  methods: {
    saveDeposit() {
      if (this.depositSelect) {
        OrderService.setListDeposit(this.exchange.id, this.depositSelect.id).then(() => {
          this.getOrderAdminById()
        })
      }
    },
    getOrderAdminById() {
      OrderService.getOrderAdminById(this.exchange.id).then((res) => {
        this.depositData = res.data.result.deposit
      })
    },
    hashFormat(val) {
      if (val.length > 58) {
        return val.slice(0, 55) + '...'
      } else {
        return val
      }
    },
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
  }
}
</script>

<style lang="scss">
.save-deposit {
  margin-top: 10px;
  max-width: 200px;
  outline: none;
  border: none;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background: rgba(27, 26, 28, 0.1);
  border-radius: 8px;
  color:  rgba(27, 26, 28, 0.2);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  &-active {
    background: #0A68F7;
    color: white;
  }

  &:hover {
    opacity: 0.9;
    transition: 0.3s ease-in-out;
  }
}
</style>
