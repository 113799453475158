<template>
  <AccountLayout>
    <template #title>
      <h2>Partner stats</h2>
    </template>
    <template #content>
      <div class="partnerStat main-block">
        <loading
          v-if="!partnerStatsData.length && isLoading"
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
          color="#0A68F7"
        />
        <div class="table-block">
          <div class="table-block-header">
            <div class="table-block-header-title">
              Partner stats
            </div>
          </div>
          <div class="table-block-table">
            <table class="table">
              <thead>
                <tr class="table-headers">
                  <th
                    v-for="(item, index) in tableTh"
                    :key="index"
                    scope="col"
                    :class="{'table-id': item.key === 'id', 'table-status': item.key === 'status'}"
                  >
                    <div
                      class="text"
                      @click="sortData(item)"
                      @mouseover="currentIndex = index"
                      @mouseleave="currentIndex = null"
                    >
                      {{ item.name }}
                      <sort-passive v-if="!item.sort" />
                      <sort-a-z v-if="item.sort === 'asc'" />
                      <sort-z-a v-if="item.sort === 'desc'" />
                      <transition name="fade">
                        <hover-text
                          v-if="currentIndex === index && item.description !== null"
                          :option="item.description"
                        />
                      </transition>
                    </div>
                  </th>
                  <th
                    ref="alert"
                    class="table-alert"
                  >
                    <div

                      @click="alertHandler"
                    >
                      <div class="text">
                        Alert - {{ selectedAlert.value }} BTC
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_4206_3129)">
                            <path
                              d="M2.25 12.75V14.25H6.75V12.75H2.25ZM2.25 3.75V5.25H9.75V3.75H2.25ZM9.75 15.75V14.25H15.75V12.75H9.75V11.25H8.25V15.75H9.75ZM5.25 6.75V8.25H2.25V9.75H5.25V11.25H6.75V6.75H5.25ZM15.75 9.75V8.25H8.25V9.75H15.75ZM11.25 6.75H12.75V5.25H15.75V3.75H12.75V2.25H11.25V6.75Z"
                              fill="#0A68F7"
                              fill-opacity="0.12"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4206_3129">
                              <rect
                                width="18"
                                height="18"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <div
                      v-if="alertHandle"
                      class="dropdown"
                    >
                      <div
                        v-for="(item, index) in dropdownItems"
                        :key="index"
                        class="dropdown-item"
                        @click="selectTimerValue(item)"
                      >
                        {{ item.value }}

                        <svg
                          v-if="selectedAlert.value === item.value"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_4068_2939)">
                            <path
                              d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                              fill="#0A68F7"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4068_2939">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </th>
                  <th class="table-edit" />
                </tr>
              </thead>
              <tbody>
                <PartnerStatItem
                  v-if="partnerStatsData.length"
                  :stats-list="partnerStatsData"
                  :alert-data="selectedAlert.value"
                />
                <tr
                  v-if="totalData"
                  class="table-row"
                  style="border-bottom: transparent"
                >
                  <td class="table-cell table-id">
                    <b style="font-size: 16px;">
                      Total:
                    </b>
                  </td>
                  <td class="table-cell table-login" />
                  <td class="table-cell table-cnt">
                    <b>{{ totalData.transactionCount }}</b>
                  </td>
                  <td class="table-cell table-profit">
                    <b>{{ formatNumber(totalData.profit) }}</b>
                  </td>
                  <td class="table-cell table-payments">
                    <b>{{ formatNumber(totalData.payments) }}</b>
                  </td>
                  <td
                    class="table-cell table-credit"
                  >
                    <b>{{ creditCalculate(totalData) }}</b>
                  </td>
                  <td class="table-cell table-edit" />
                </tr>
              </tbody>
            </table>
            <no-data-block v-if="!partnerStatsData || !partnerStatsData.length && !isLoading" />
          </div>
        </div>
      </div>
      <PayModal
        v-if="PartnersStatsData"
        :data="PartnersStatsData"
      />
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/admin/AccountLayout.vue";
import SortPassive from "../../icons/sorting/sort-passive";
import SortAZ from "../../icons/sorting/sort-a-z";
import SortZA from "../../icons/sorting/sort-z-a";
import HoverText from "../coins/partials/hoverText";
import PartnerStatItem from "./partials/PartnerStatItem";
import filterMixin from "../../mixins/filter.mixin";
import helperMixin from "../../mixins/helper.mixin";
import PayModal from "./partials/PayModal";
import {mapGetters} from 'vuex';
import {TABLE_TH} from "./partnerData";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import Loading from 'vue-loading-overlay';

export default {
  name: "PartnerStat",
  components: {
    NoDataBlock,
    PayModal,
    PartnerStatItem,
    HoverText,
    SortZA,
    SortAZ,
    SortPassive,
    AccountLayout,
    Loading
  },
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      search: null,
      isLoading: false,
      isLoginSearch: false,
      searchList: [],
      searchListOrigin: [],
      currentSorting: 'login',
      partnerStatsData: [],
      totalData: null,
      isRows: false,
      currentIndex: null,
      tableTh: TABLE_TH,
      alertStatus: null,
      alertHandle: false,
      dropdownItems: [
        {value: 0.01},
        {value: 0.1},
        {value: 1},
        {value: 10},
        {value: 100},

      ],
      selectedAlert: {value: 1},
    }
  },
  computed: {
    ...mapGetters({
      PartnersStatsData: 'getPartnersStatsData',
      IsPayModal: 'getIsPayModal',
    }),
    PartnerStats: {
      get() {
        return this.$store.state.partnersStats;
      },
      set(value) {
        this.$store.commit('setPartnersStats', value);
      }
    },
  },
  mounted() {
    this.getStatsList();
    this.totalHandler();
    document.addEventListener('click', this.alertClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.alertClickOutside);
  },
  methods: {
    alertClickOutside(event) {
      if (this.$refs.alert && !this.$refs.alert.contains(event.target)) {
        this.alertHandle = false
      }
    },
    formatNumber(num) {
      const number = parseFloat(num);
      if (number !== 0) {
        const [integerPart, decimalPart] = number.toFixed(5).split(".");
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${formattedIntegerPart}.${decimalPart}`;
      } else {
        return number.toString();
      }
    },
    totalHandler() {
      if (this.partnerStatsData) {
        this.totalData = this.partnerStatsData.reduce((accumulator, item) => {
          return {
            transactionCount: accumulator.transactionCount + parseFloat(item.transactionCount),
            profit: accumulator.profit + parseFloat(item.profit),
            payments: accumulator.payments + parseFloat(item.payments)
          };
        }, {transactionCount: 0, profit: 0, payments: 0});
      }
    },
    creditCalculate(stat) {
      if (stat) {
        const profit = parseFloat(stat.profit);
        const payments = parseFloat(stat.payments);
        return (profit - payments).toFixed(5);
      }
    },
    selectTimerValue(item) {
      this.selectedAlert = item;
      this.alertHandle = false
    },
    alertHandler() {
      this.alertHandle = !this.alertHandle
    },
    getStatsList() {
      this.isLoading = true
      this.$store.dispatch('getPartnersStats').then(() => {
        this.partnerStatsData = this.PartnerStats;
        this.totalHandler();
      });
    },
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });
      switch (type.key) {
        case 'id':
          this.PartnerStats = this.PartnerStats.sort((a, b) => {
            return type.sort === 'asc' ? a.id - b.id : b.id - a.id;
          });
          break;
        case 'login':
          this.PartnerStats = this.PartnerStats.sort((a, b) => {
            const aCount = a.login.localeCompare(b.login);
            const bCount = b.login.localeCompare(a.login);

            return type.sort === 'asc' ? aCount : bCount;
          });
          break;
        case 'ctn':
          this.PartnerStats = this.PartnerStats.sort((a, b) => {
            return type.sort === 'asc' ? b.transactionCount - a.transactionCount : a.transactionCount - b.transactionCount;
          });
          break;
        case 'profit':
          this.PartnerStats = this.PartnerStats.sort((a, b) => {
            return type.sort === 'asc' ? b.profit - a.profit : a.profit - b.profit;
          });
          break;
        case 'payments':
          this.PartnerStats = this.PartnerStats.sort((a, b) => {
            return type.sort === 'asc' ? b.payments - a.payments : a.payments - b.payments;
          });
          break;
        case 'credit':
          this.PartnerStats = this.PartnerStats.sort((a, b) => {
            const creditA = a.profit - a.payments;
            const creditB = b.profit - b.payments;
            return type.sort === 'asc' ? creditB - creditA : creditA - creditB;
          });
          break;
      }
      this.partnerStatsData = this.PartnerStats;
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/pages/partner-stat/partner-stat";
</style>
