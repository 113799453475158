<template>
  <tr
    v-for="(network, index) in networksList"
    :key="index"
    class="table-row"
  >
    <td class="table-cell td-id">
      {{ network.id }}
    </td>
    <td class="table-cell">
      {{ network.shortName }}
    </td>
    <td class="table-cell">
      {{ network.marketUrl ? network.marketUrl : '—' }}
    </td>
    <td class="table-cell td-active">
      <img
        v-if="network.status === true"
        src="../../../assets/img/icons/true.svg"
        alt=""
      >
      <img
        v-if="network.status === false"
        src="../../../assets/img/icons/false.svg"
        alt=""
      >
    </td>
    <td class="table-cell table-edit">
      <div class="info">
        <div
          class="info-edit"
          @click="openNetworkEditPopup(network)"
        >
          <img
            src="../../../assets/img/icons/edit-icon.svg"
            alt=""
          >
        </div>
      </div>
    </td>
    <NetworkEditModal
      v-if="isNetworkEditPopup === network.id"
      :data="network"
      :update-network-list="updateNetworkList"
      @close="closeNetworkEditPopup"
    />
  </tr>
</template>

<script>
import {MAIN_DOMAIN} from "@/store/config";
import NetworkEditModal from '@/pages/coins/partials/NetworkEditModal.vue';

export default {
  name: "NetworkListItem",
  components: {NetworkEditModal},
  props: {
    networksList: {
      type: Array,
      default: null,
    },
    updateNetworkList: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isNetworkEditPopup: null,
      search: null,
      domain: MAIN_DOMAIN
    }
  },
  mounted() {
    this.scrollHandler();
  },
  methods: {
    scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.table-coin');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 40) {
            el.classList.remove('sticky');
          }
        });
      });
    },
    openNetworkEditPopup(network) {
      this.isNetworkEditPopup = network.id;
    },
    closeNetworkEditPopup() {
      this.isNetworkEditPopup = null;
    },
  },
}
</script>

<style lang="scss">
.td-id {
  padding-left: 32px;
}
</style>
