export const TABLE_TH = [
    {
        name: 'ID',
        sort: '',
        noSort: false,
        key: 'id',
        description: null
    },
    {
        name: 'Fav',
        sort: '',
        noSort: false,
        key: 'fav',
        description: null
    },
    {
        name: 'Email',
        sort: '',
        noSort: false,
        key: 'login',
        description: null
    },
    {
        name: 'Role',
        sort: '',
        noSort: false,
        key: 'role',
        description: null
    },
    {
        name: 'Active',
        sort: '',
        noSort: false,
        key: 'active',
        description: null
    },
    {
        name: 'Create user',
        sort: '',
        noSort: false,
        key: 'createDate',
        description: null
    },
    {
        name: 'Profile',
        sort: '',
        noSort: true,
        key: 'profile',
        description: null
    },

];

export const TABLE_TH_ACTIVITY = [
    {
        name: 'No',
        sort: 'none',
        key: 'index',
        description: ''
    },
    {
        name: 'Create date',
        sort: '',
        key: 'createDate',
        description: ''
    },
    {
        name: 'User agent',
        sort: '',
        key: 'userAgent',
        description: ''
    },
    {
        name: 'IP Address',
        sort: '',
        key: 'ipAddress',
        description: ''
    },
]
