<template>
  <div class="AccountLayoutNew">
    <div
      v-if="appMode === 'development'"
      class="text-strip-mob"
    >
      /// DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode
    </div>
    <div class="row">
      <SuccessModal v-if="SuccessModal.name" />
      <ErrorModal v-if="ErrorModal.name" />
      <div
        class="mobNav"
        :class="{'mobNav_dev': appMode === 'development', 'mobNav_prod': appMode === 'production'}"
      >
        <div class="mobNav-block">
          <div
            class="burger"
            :class="{'burger_dev': appMode === 'development'}"
            @click="toggleNav"
          >
            <menu-burger />
          </div>
          <router-link :to="{ name: 'Main dashboard'}">
            <div class="title-container">
              <slot name="title" />
            </div>
            <div class="logo-container">
              <logo-dev />
            </div>
          </router-link>
        </div>
        <div class="account-mob">
          <user-profile />
        </div>
      </div>
      <div
        class="deskNav"
        :class="{'deskNav-Mob': mobNav,
                 'deskNav-Mob-dev': appMode === 'development'}"
      >
        <div
          v-if="appMode === 'development'"
          class="text-strip-desktop"
        >
          /// DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode  ///  DEV mode
        </div>
        <PerfectScrollbar
          class="dashboard-nav d-xl-block"
          :class="{'dashboard-nav_dev': appMode === 'development', 'dashboard-nav_prod': appMode === 'production'}"
        >
          <div class="logo">
            <router-link :to="{ name: 'Main dashboard'}">
              <logo-dev />
            </router-link>
          </div>
          <div class="nav-menu">
            <div
              v-for="(item, index) in $router.options.routes"
              :key="index"
              class="nav-menu-item"
            >
              <div
                v-if="item.meta && item.meta.isDrop"
                @click="openMenuHandler(index)"
              >
                <div
                  class="btn-link"
                  :class="{
                    'router-link-active router-link-exact-active':
                      isRouteActive(item) //|| currentNav === index
                  }"
                >
                  <div class="icon">
                    <component :is="item.meta.icon" />
                  </div>
                  {{ item.name }}
                  <div
                    class="drop"
                    :class="{
                      'drop_opened-dev': currentNav === index && appMode === 'development',
                      'drop_opened-prod': currentNav === index && appMode === 'production'}"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4686_85540)">
                        <path
                          d="M7 10L12 15L17 10H7Z"
                          fill="white"
                          fill-opacity="0.54"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4686_85540">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="matrix(0 1 -1 0 24 0)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div
                  v-if="currentNav === index"
                  class="nav-menu-item_drop"
                >
                  <div
                    v-for="(child, id) in item.children.filter(route => route.meta.isMenu)"
                    :key="id"
                  >
                    <router-link
                      :to="{ name: child.name }"
                      class="btn-link"
                    >
                      <div class="icon">
                        <component :is="child.meta.icon" />
                      </div>
                      {{ child.name }}
                    </router-link>
                  </div>
                </div>
              </div>
              <router-link
                v-if="((item.meta.isMenu && item.meta.isProd) || item.meta.isMenu) && !item.meta.isDrop"
                :to="{ name: item.name }"
                class="btn-link"
                :class="{
                  'router-link-active router-link-exact-active':
                    (($route.name === 'Exchanges Multi' || $route.name === 'Exchanges 1inch') && item.name === 'Exchanges retro') ||
                    ($route.name === 'Coin1inch' && item.name === 'Coins'),
                  'router-link-exact-active-dev': appMode === 'development',
                  'router-link-exact-active-prod': appMode === 'production'
                }"
              >
                <div class="icon">
                  <component :is="item.meta.icon" />
                </div>
                {{ item.name }}
              </router-link>
            </div>
            <div
              v-if="UserAcc"
              class="nav-menu-item"
            >
              <!--              <router-link
                :to="{name: 'Trade balance', params: {id: UserAcc?.id}}"
              >
                <div class="icon">
                  <trade-icon />
                </div>
                Trade
              </router-link>-->
            </div>
            <div class="logout">
              <div class="nav-menu-item">
                <a
                  href="#"
                  class="btn-link"
                  @click="logout"
                >
                  <div class="icon">
                    <log-out class="logout-icon" />
                  </div>
                  Logout
                </a>
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
      <div
        class="content"
        :class="{ 'blur': mobNav, 'content_mirror': $route.name === 'Balance mirror', 'content_turnover': $route.name === 'Mirror turnover' }"
      >
        <div class="account-head">
          <transition
            name="committee"
            appear
          >
            <div class="title">
              <slot name="title" />
            </div>
          </transition>
        </div>
        <slot name="content" />
      </div>
      <update-notification v-if="Notification.name" />
      <SuccessNotificationNode v-if="NotificationNode.name" />

      <schedule-popup v-if="schedulePopup" />
    </div>
  </div>
</template>

<script>
import AddPartner from "../../pages/users/partials/AddPartner.vue";
import AddAdmin from "../../pages/users/partials/AddAdmin.vue";
import SuccessModal from "../../pages/notification/SuccessModal.vue";
import ErrorModal from "../../pages/notification/ErrorModal.vue";
import UpdateNotification from "../../pages/notification/UpdateNotification.vue";
import {mapGetters} from 'vuex';
import MenuBurger from "../../icons/menuBurger.vue";
import LogoMob from "../../icons/logoMob.vue";
import CloseMenu from "../../icons/closeMenu.vue";
import LogOut from "../../icons/logOut.vue";
import Logo from "../../icons/logo.vue";
import UserService from "../../services/user.service";
import MultiExchangeService from "@/services/multiExchange.service";
import UserProfile from "@/components/UserProfile.vue";
import TradeIcon from "@/assets/img/icons/menu/tradeIcon.vue";
import SchedulePopup from "@/components/admin/SchedulePopup.vue";
import LogoDev from "@/icons/logo-dev.vue";
import SuccessNotificationNode from "@/pages/exchanges/partials/SuccessNotificationNode.vue";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

export default {
  name: "AccountLayoutNew",
  components: {
    SuccessNotificationNode,
    LogoDev,
    SchedulePopup,
    TradeIcon,
    UserProfile,
    Logo,
    LogOut,
    PerfectScrollbar,
    CloseMenu, LogoMob, MenuBurger, UpdateNotification, ErrorModal, SuccessModal, AddAdmin, AddPartner
  },
  data() {
    return {
      appMode: process.env.VUE_APP_MODE,
      isActive: false,
      mobNav: false,
      listAccounts: null,
      schedulePopup: false,
      currentNav: false
    }
  },
  computed: {
    ...mapGetters({
      IsAdminModal: 'getIsAdminModal',
      IsPartnerModal: 'getIsPartnerModal',
      SuccessModal: 'getSuccess',
      ErrorModal: 'getError',
      Notification: 'getNotification',
      NotificationNode: 'getNotificationNode',
      UserAcc: 'getUserAcc',
      UserAccList: 'getUserAccList',
    }),
    User: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit('setUser', value);
      }
    },
  },
  watch: {
    $route() {
      this.setCurrentNav();
    },
  },
  created() {
  },
  mounted() {
    this.setCurrentNav();
    this.checkRole();
    this.emitter.on('schedule-open-popup', (status) => {
      this.schedulePopup = status
    });
  },
  beforeUnmount() {
    document.body.classList.remove('modal-open');
  },
  methods: {
    setCurrentNav() {
      const currentRouteName = this.$route.name;
      const routes = this.$router.options.routes;

      for (let i = 0; i < routes.length; i++) {
        if (routes[i].children) {
          for (let j = 0; j < routes[i].children.length; j++) {
            if (routes[i].children[j].name === currentRouteName) {
              this.currentNav = i;
              return;
            }
          }
        }
      }
      this.currentNav = null;
    },
    isRouteActive(item) {
      return item.children.some(route => route.name === this.$route.name);
    },
    logout() {
      this.$store.dispatch('logout');
    },
    toggleNav() {
      if (!this.mobNav) {
        this.mobNav = true;
        document.body.classList.add('modal-open');
      } else {
        this.mobNav = false;
        document.body.classList.remove('modal-open');
      }
    },
    checkRole() {
      UserService.getProfile().then((res) => {
        if (res) {
          if (res.data.result.roles.includes('admin')) {
            this.$store.dispatch('getProfile')
          } else {
            this.$store.dispatch('logout');
          }
        }
      })
    },
    openMenuHandler(index) {
      if (this.currentNav !== index) {
        this.currentNav = index
      } else {
        this.currentNav = false
      }
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/layout/layout-new";
@import "../../assets/styles/layout/table";
</style>
