import service from '../plugins/request';

class CoinService {
    /**
     * @returns {Promise<AxiosResponse<T>>}
     */
    getCoinsList() {
        return service.get('/api/v2/Coins/admin/all');
    }


    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCoinsListData() {
        return service.get('/api/Home/v1/coins')
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCoinById(id) {
        return service.get(`/api/v2/Coins/admin/coin/${id}`);
    }

    /**
     * @param id
     * @param query
     * @returns {Promise<AxiosResponse<T>>}
     */
    editCoin( query) {
        return service.put(`/api/v2/Coins/admin/update`, query);
    }


    /**
     * @param query
     * @returns {Promise<AxiosResponse<T>>}
     */
    addCoin(query) {
        return service.post('/api/v2/Coins/admin/create', query);
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    getCoinNetwork(id) {
        return service.get( `/api/coin/${id}/network`)
    }

    /**
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCoins1inchList() {
        return service.get('/api/Coin1inch/all');
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCoin1inchById(id) {
        return service.get(`/api/Coin1inch/${id}`);
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveCoin1inch(query) {
        return service.post('/api/Coin1inch/update', query);
    }

    /**
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAddressLockedList() {
        return service.get('/api/Order1inch/admin/addressLocked');
    }

    getNetworkList() {
        return service.get('/api/v2/Network/admin/all');
    }

    EditNetwork(id,query) {
        return service.post(`/api/v2/Network/update/${id}`,query);
    }
}

export default new CoinService();
