<template>
  <AccountLayout>
    <template #title>
      <div class="layout-header">
        <h2>List of networks</h2>
      </div>
    </template>
    <template #content>
      <div class="coinsList main-block">
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="false"
          color="#0A68F7"
        />
        <div class="table-block">
          <div class="table-block-header">
            <div class="table-block-header-title">
              All networks
            </div>
            <div class="table-block-header-filters">
              <div class="table-block-header-filters-search">
                <input
                  type="text"
                  placeholder="Network search"
                  @keyup="searchNetwork"
                >
              </div>
            </div>
          </div>
          <div class="table-block-table">
            <table class="table">
              <thead>
                <tr class="table-headers networks-list">
                  <th
                    v-for="(item, index) in tableTh"
                    :key="index"
                    scope="col"
                    :class="{'table-name': item.key === 'name', 'table-id': item.key === 'id','table-edit': item.key === 'edit'}"
                  >
                    <div
                      class="text"
                      @click="sortData(item)"
                      @mouseover="currentIndex = index"
                      @mouseleave="currentIndex = null"
                    >
                      {{ item.name }}
                      <sort-passive v-if="!item.sort && !item.noSort" />
                      <sort-a-z v-if="item.sort === 'asc' && !item.noSort" />
                      <sort-z-a v-if="item.sort === 'desc' && !item.noSort" />
                      <transition name="fade">
                        <hover-text
                          v-if="currentIndex === index && item.description !== null"
                          :option="item.description"
                        />
                      </transition>
                    </div>
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                <NetworkListItem
                  v-if="networkListData"
                  :networks-list="networkListData"
                  :update-network-list="updateNetworkList"
                />
              </tbody>
            </table>
            <no-data-block v-if="!networkListData && !isLoading" />
          </div>
        </div>
        <paged-pagination
          v-if="networkListData && networkListData.length && !isSearch"
          :total="batch.length - 1"
          :current="page"
          @change-page="pageHandler"
          @prev-page="pageHandler"
          @next-page="pageHandler"
        />
      </div>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/admin/AccountLayout.vue";
import {mapGetters} from 'vuex';
import HoverText from "./partials/hoverText";
import filterMixin from "../../mixins/filter.mixin";
import helperMixin from "../../mixins/helper.mixin";
import SortPassive from "../../icons/sorting/sort-passive";
import SortAZ from "../../icons/sorting/sort-a-z";
import SortZA from "../../icons/sorting/sort-z-a";
import {TABLE_TH} from "./networkData";
import PagedPagination from "@/components/admin/PagedPagiantion.vue";
import NoDataBlock from "@/components/admin/NoDataBlock.vue";
import Loading from 'vue-loading-overlay';
import NetworkListItem from '@/pages/coins/partials/NetworkListItem.vue';

export default {
  name: "NetworksList",
  components: {
    NetworkListItem,
    NoDataBlock,
    PagedPagination,
    SortZA,
    SortAZ,
    SortPassive,
    HoverText,
    AccountLayout,
    Loading
  },
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      search: null,
      originNetworks: null,
      isLoading: false,
      batch: [],
      page: 1,
      networkListData: [],
      currentPerPage: 20,
      options: {
        chunk: 4
      },
      currentIndex: false,
      tableTh: TABLE_TH,
      isSearch: false
    }
  },
  computed: {
    ...mapGetters({

    }),
    Networks: {
      get() {
        return this.$store.state.networkList;
      },
      set(value) {
        this.$store.commit('setNetworks', value);
      }
    },
  },
  watch: {
  },
  mounted() {
    this.getNetworkListData();
  },
  methods: {
    updateNetworkList() {
      this.$store.dispatch('getNetworkList').then(() => {
        this.originNetworks = [...this.Networks];
        const batch = this.batchData(this.Networks, this.currentPerPage);
        this.networkListData = batch.current;
        this.batch = batch.total;
      });
    },
    getNetworkListData() {
      this.isLoading = true
      this.$store.dispatch('getNetworkList').then(() => {
        this.originNetworks = [...this.Networks];
        const batch = this.batchData(this.Networks, this.currentPerPage);
        this.networkListData = batch.current;
        this.batch = batch.total;
        this.isLoading = false
      });
    },
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {

        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });
      switch (type.key) {
        case 'id':
          this.Networks = this.Networks.sort((a, b) => {
            return type.sort === 'asc' ? a.id - b.id : b.id - a.id;
          });
          break;

        case 'name':
          this.Networks = this.Networks.sort((a, b) => {
            const aCount = a.shortName.localeCompare(b.shotName);
            const bCount = b.shortName.localeCompare(a.shotName);

            return type.sort === 'asc' ? aCount : bCount;
          });
          break;

        case 'marketUrl':
          this.Networks = this.Networks.sort((a, b) => {
            const aCount = (a.marketURL || '').localeCompare(b.marketURL || '');
            const bCount = (b.marketURL || '').localeCompare(a.marketURL || '');

            return type.sort === 'asc' ? aCount : bCount;
          });
          break;

        case 'active':
          this.Networks = this.Networks.sort((a, b) => {
            return type.sort === 'asc' ? a.isActive - b.isActive : b.isActive - a.isActive;
          });
          break;
      }
      this.networkListData = this.batchData(this.Networks, this.currentPerPage).current;
    },
    searchNetwork(el) {
      const value = el.target.value.toLowerCase();

      this.Networks = this.originNetworks;

      if (value && value.length) {
        this.Networks = this.Networks.filter((network) => {
          const isName = network.name.toLowerCase().indexOf(value) > -1;
          const isShortName = network.shotName.toLowerCase().indexOf(value) > -1;

          this.isSearch = true
          return isName || isShortName;
        });
      } else {
        this.isSearch = false
      }
      this.networkListData = this.batchData(this.Networks, this.currentPerPage).current;
    },
    pageHandler(page) {
      this.page = page
      this.networkListData = this.batch[page];
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/components/search";
@import "../../assets/styles/pages/coins/coins";

.networks-list {
  th.table-id {
    width: 60px;
  }
  th.table-name {
    width: 93px;
  }

  th.table-edit {
    width: 35px;
    padding-right: 32px;
  }
}
</style>
