<template>
  <div
    class="add-user"
    tabindex="0"
    @keydown.esc="closePartnerModal"
  >
    <div class="add-user-block">
      <div class="title-block">
        <div class="title-block-text">
          Add partner
        </div>
        <div class="title-block-close">
          <img
            src="../../../assets/img/close.svg"
            alt=""
            @click="closePartnerModal"
          >
        </div>
      </div>
      <div class="input-item">
        <div class="input-item-title">
          Enter email
        </div>
        <input
          v-model.trim="form.email"
          type="text"
          @focus="isLoginBusy = false;"
        >
        <div
          v-if="isLoginBusy"
          class="input-item-desc"
          style="color: #ff783e"
        >
          This user already exists
        </div>
        <div
          v-if="emailError"
          class="input-item-desc"
          style="color: #ff783e"
        >
          {{ emailError }}
        </div>
      </div>
      <div
        class="input-item"
        :class="{'errorPassword': errors.includes('new-password-wrong-length') || errors.includes('new-password-has-not-capitalize') || errors.includes('new-password-has-not-special-character')}"
      >
        <div class="input-item-title">
          Create password
        </div>
        <input
          v-model="form.password"
          type="password"
          class="input"
          @keyup="validationPassword"
        >
        <span v-if="errors.includes('new-password-wrong-length')" class="input-item-desc">
          at least 7 characters
        </span>
        <span v-if="errors.includes('new-password-has-not-capitalize')" class="input-item-desc">
          at least one uppercase letter
        </span>
        <span v-if="errors.includes('new-password-has-not-special-character')" class="input-item-desc">
          At least one special character (e.g.,")
        </span>
      </div>
      <div
        class="input-item"
        :class="{'errorPassword': errors.includes('repeat-password')}"
      >
        <div class="input-item-title">
          Repeat password
        </div>
        <input
          v-model="form.repeatPassword"
          type="password"
          class="input"
          :class="{'errorPassword': errors.includes('repeat-password')}"
          @keyup="validationPassword"
        >
        <div
          v-if="errors.includes('repeat-password')"
          class="input-item-desc"
        >
          Passwords are not identical
        </div>
      </div>
      <div class="buttons">
        <div
          class="button cancel"
          @click="closePartnerModal"
        >
          Cancel
        </div>
        <div
          class="button confirm"
          @click="submitCreate"
        >
          Create
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import UserService from '../../../services/user.service'
    import {hasPasswordCapitalize, hasPasswordSpecialCharacter, isPasswordValidLength} from '@/utils/helpers';

    export default {
        name: "AddPartner",
        props: {
          updateList: {
            type: Function,
            required: true
          },
        },
        data() {
            return {
                form: {
                    password: '',
                    repeatPassword: '',
                    email: ''
                },
                isLoginBusy: false,
                isError: false,
                emailError: false,
                errors: []
            }
        },
        methods: {
          validationPassword() {
            this.errors = [];

            if (this.form.password && !isPasswordValidLength(this.form.password)) {
              this.errors.push('new-password-wrong-length');
            }

            if (this.form.password && !hasPasswordCapitalize(this.form.password)) {
              this.errors.push('new-password-has-not-capitalize');
            }

            if (this.form.password && !hasPasswordSpecialCharacter(this.form.password)) {
              this.errors.push('new-password-has-not-special-character');
            }

            if (this.form.repeatPassword.length !== 0 && this.form.repeatPassword !== this.form.password) {
              this.errors.push('repeat-password');
            }
          },
            closePartnerModal() {
                this.$store.commit('setIsPartnerModal', false);
            },
            repeatPasswordHandler() {
                this.isError = this.form.password !== this.form.repeatPassword;
            },
            submitCreate() {
              console.log(this.form.password.length === '',this.form.repeatPassword.length === '')
                if (this.errors.length !== 0 || this.form.password === '' || this.form.repeatPassword === '') {
                  return false
                }
                UserService.checkLogin(this.form.email).then((result) => {
                    this.repeatPasswordHandler();

                    if (result.data.result === 'empty' && !this.isError) {
                        this.isLoginBusy = false;
                        UserService.registerPartner(this.form).then(() => {
                            this.closePartnerModal();
                            this.updateList()
                            this.successPopup();
                        }).catch((error) => {
                          if (error.response?.data?.errors.Email) {
                            this.emailError = error.response?.data?.errors.Email[0];
                          } else {
                            this.errorPopup();
                          }
                        });
                    }

                    if (result.data.result === 'busy') {
                        this.isLoginBusy = true;
                    }
                })
            },
            successPopup() {
                this.$store.commit('setSuccess', {
                    name: 'Add partner success',
                    value: this.form.email,
                });
            },
            errorPopup() {
                this.$store.commit('setError', {
                    name: 'Add partner error',
                    value: this.form.email,
                });
            },
        }
    }
</script>

<style lang="scss">
</style>
